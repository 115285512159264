@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  label {
    @apply text-sky-950 font-bold;
  }
  button {
    @apply text-white font-bold py-2 px-4 rounded-lg cursor-pointer focus:outline-none focus:ring-4 hover:ring-2 shadow-md;
  }

  input,
  select,
  textarea {
    @apply w-full py-2 px-4 rounded-lg border-2 border-sky-950 focus:outline-none focus:ring-2;
  }

  input:checked ~ .dot {
    @apply translate-x-full;
  }

  input:checked ~ .dotBG {
    @apply bg-sky-700 ring-sky-300 ring-2;
  }

  th,
  td {
    @apply p-2;
  }

  @media screen and (max-width: 768px) {
    sidebar,
    main {
      height: calc(100% - 5rem);
    }
  }

  @media screen and (min-width: 769px) {
    sidebar,
    main {
      height: calc(100%);
    }
  }
}

@layer components {

  .text-alternate {
    color: #FEF506;
  }

  .text-main-light {
    color: #2240fb;
  }

  .text-main-dark {
    color: #1c1c95;
  }

  .bg-main-light {
    background-color: #2240fb;
  }

  .bg-main-dark {
    background-color: #1c1c95;
  }

  .btn-success {
    @apply bg-gradient-to-r from-green-600 to-green-800 hover:bg-green-700 hover:ring-green-500 shadow-green-500;
  }

  .btn-danger {
    @apply bg-gradient-to-r from-red-600 to-red-800 hover:bg-red-700 hover:ring-red-500 shadow-red-500;
  }

  .btn-warning {
    @apply bg-gradient-to-r from-yellow-600 to-yellow-800 hover:bg-yellow-700 hover:ring-yellow-500 shadow-yellow-500;
  }

  .btn-info {
    @apply bg-gradient-to-r from-blue-600 to-blue-800 hover:bg-blue-700 hover:ring-blue-500 shadow-blue-500;
  }

  .btn-primary {
    @apply bg-gradient-to-r from-sky-800 to-sky-950 hover:bg-sky-700 hover:ring-sky-700 shadow-sky-700;
  }

  .btn-secondary {
    @apply bg-gradient-to-r from-zinc-200 to-zinc-400 hover:bg-gray-700 hover:ring-gray-500 shadow-zinc-500;
  }

  .btn-outline-info {
    @apply text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white;
  }

  .btn-outline-primary {
    @apply text-sky-600 border-sky-600 hover:bg-sky-600 hover:text-white;
  }

  .btn-outline-secondary {
    @apply text-zinc-400 border-zinc-400 hover:bg-zinc-400 hover:text-white;
  }

  .btn-outline-success {
    @apply text-green-600 border-green-600 hover:bg-green-600 hover:text-white;
  }

  .btn-outline-danger {
    @apply text-red-600 border-red-600 hover:bg-red-600 hover:text-white;
  }

  .btn-outline-warning {
    @apply text-yellow-600 border-yellow-600 hover:bg-yellow-600 hover:text-white;
  }

  .btn-outline-light {
    @apply text-gray-600 border-gray-600 hover:bg-gray-600 hover:text-white;
  }

  .btn-outline-danger.checked {
    @apply text-white border-red-600 bg-red-600;
  }

  .btn-outline-info.checked {
    @apply text-white border-blue-600 bg-blue-600;
  }

  .btn-outline-primary.checked {
    @apply text-white border-sky-600 bg-sky-600;
  }

  .btn-outline-secondary.checked {
    @apply text-white border-zinc-400 bg-zinc-400;
  }

  .btn-outline-success.checked {
    @apply text-white border-green-600 bg-green-600;
  }

  .btn-outline-warning.checked {
    @apply text-white border-yellow-600 bg-yellow-600;
  }

  .hidden-sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    width: 0;
  }

  .visible-sidebar {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

}
